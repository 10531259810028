import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-center align-items-center mt-5 mb-15" }
const _hoisted_2 = { class: "d-flex flex-column align-items-center" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "d-flex justify-content-center align-items-center" }
const _hoisted_5 = { class: "ml-2 font-size-large" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "d-flex justify-content-center align-items-center" }
const _hoisted_8 = { class: "ml-2 font-size-large" }
const _hoisted_9 = { class: "d-flex justify-content-center align-items-center" }
const _hoisted_10 = { class: "ml-4 font-size-large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (this.$store.getters.currentSettings.is_sso_activated)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_router_link, {
              to: "/auth/callback",
              class: "d-flex align-items-center justify-content-center btn btn-primary btn-block mb-6 fixed-width fixed-height"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _cache[0] || (_cache[0] = _createElementVNode("i", {
                    class: "fas fa-user",
                    style: {"font-size":"16px"}
                  }, null, -1)),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("auth.loginHomeUser")), 1)
                ])
              ]),
              _: 1
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_router_link, {
              to: "/sign-in",
              class: "d-flex align-items-center justify-content-center btn btn-primary btn-block mb-6 fixed-width fixed-height"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _cache[1] || (_cache[1] = _createElementVNode("i", {
                    class: "fas fa-user",
                    style: {"font-size":"16px"}
                  }, null, -1)),
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("auth.loginHomeUser")), 1)
                ])
              ]),
              _: 1
            })
          ])),
      _createVNode(_component_router_link, {
        to: "/sign-in",
        class: "d-flex align-items-center justify-content-center btn btn-secondary btn-block fixed-width fixed-height"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_9, [
            _cache[2] || (_cache[2] = _createElementVNode("i", {
              class: "fas fa-user-cog",
              style: {"font-size":"16px"}
            }, null, -1)),
            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("auth.loginHomeAdmin")), 1)
          ])
        ]),
        _: 1
      })
    ])
  ]))
}